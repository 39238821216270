const state = {
  createAdAccount: localStorage.getItem('createAdAccount') ? JSON.parse(localStorage.getItem('createAdAccount')) : {},
};
const mutations = {
  setCreateAdAccount: (state, data) => {
    console.log(data, 'setCreateAdAccount');
    state.createAdAccount = { ...data };
    localStorage.setItem('createAdAccount', JSON.stringify(state.createAdAccount));
  },
};
export default {
  namespaced: true,
  state,
  mutations,
};
